import React, {useEffect, useRef, useState} from "react";
import {Tab, Tabs, TabList, TabPanel} from "react-tabs";
import "./AssetDiscovery.scss";
import {IOSSwitch, InputBox} from "../../components/reusableComponents/FormFields/FormFields";
import searchWhite from "../../assets/svg/searchWhite.svg";
import commercialImg from "../../assets/Image/assetDiscovery/commercial.png";
import clockImg from "../../assets/Image/assetDiscovery/clock.png";
import eyelImg from "../../assets/Image/assetDiscovery/eye.png";
import rewindImg from "../../assets/Image/assetDiscovery/rewind.png";
import DropDown from "../../components/reusableComponents/Dropdown/Dropdown";
import SFICOn from "../../assets/svg/SF.svg";
import {FormControlLabel, Input, Modal, Slider} from "@mui/material";
import {PrimaryButton, PrimaryButtonSvgFirst} from "../../components/reusableComponents/Buttons/Buttons";
import Propcard1 from "../../assets/Image/assetDiscovery/propcard1.png";
import ProgressBar from "../../components/reusableComponents/ProgressBar/ProgressBar";
import AdvancedFilters from "./AdvancedFilters";
import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";
import AiSearch, {AiSearch1, AiSearch2} from "./AiSearch";
import AssetInformation2 from "../DealsRoom/AssetInformation2";
import {useSelector} from "react-redux";
import axios from "axios";
import moment from "moment";
import {useDispatch} from "react-redux";
import {fetchCrownFunding, getAsset} from "../../utils/Axios";
import {setAssetId, setAssetName, setChatId, setUserAssetData} from "../../store/reducers/userDetailsSlice";
import {FormatUSD, daysAgo, formatUSD} from "../../utils/helpers";
import LoaderOverlay from "../../components/reusableComponents/Loader/index";
import MiniMap from "./Map/MiniMap";
import {Label, NotebookPanel, MessageBox, ComparisionPanel, SharePanel, InvestorFeedPanel, BookmarkPanel} from "./assets/Assect";
import {PhoneSvg, BookmarkSvg, NotebookSvg, CompareSvg, ShareSvg, PiechartSvg, SliderIcon} from "./Svgimages/svgallimnages";
import {GetSyndicates} from "../../utils/Axios";
import {setCrowdfundingList, selectCrowdfunding} from "../../store/reducers/crowdfundingSlice";
import useAuthConfig from "../../utils/Config";

function PropertyInfoTab({tabNames, collapsed, card, items, assetIdList}) {
  return (
    <Tabs
      selectedTabClassName="active"
      defaultIndex={(collapsed && -1) || 0}
    >
      <TabList>
        <Tab>
          <PhoneSvg />
          {tabNames && <span>Contacts</span>}
        </Tab>
        <Tab>
          <BookmarkSvg />
          {tabNames && <span>Save</span>}
        </Tab>
        <Tab>
          <NotebookSvg />
          {tabNames && <span>Add Note</span>}
        </Tab>
        <Tab>
          <CompareSvg />
          {tabNames && <span>Compare</span>}
        </Tab>
        <Tab>
          <ShareSvg />
          {tabNames && <span>Share</span>}
        </Tab>
        <Tab>
          <PiechartSvg />
          {tabNames && <span>Investor</span>}
        </Tab>
      </TabList>
      <TabPanel>
        <ContactPanel
          card={card}
          assetIdList={assetIdList}
        />
      </TabPanel>
      <TabPanel>
        <BookmarkPanel />
      </TabPanel>
      <TabPanel>
        <NotebookPanel />
      </TabPanel>
      <TabPanel>
        <ComparisionPanel />
      </TabPanel>
      <TabPanel>
        <SharePanel />
      </TabPanel>
      <TabPanel>
        <InvestorFeedPanel />
      </TabPanel>
    </Tabs>
  );
}

function Accectdata({tabNames, collapsed, item, card, assetIdList}) {
  return (
    <Tabs
      selectedTabClassName="active"
      defaultIndex={(collapsed && -1) || 0}
    >
      <TabList>
        <Tab>
          <PhoneSvg />
          {tabNames && <span>Contacts</span>}
        </Tab>
        <Tab>
          <BookmarkSvg />
          {tabNames && <span>Save</span>}
        </Tab>
        <Tab>
          <NotebookSvg />
          {tabNames && <span>Add Note</span>}
        </Tab>
        <Tab>
          <CompareSvg />
          {tabNames && <span>Compare</span>}
        </Tab>
        <Tab>
          <ShareSvg />
          {tabNames && <span>Share</span>}
        </Tab>
        <Tab>
          <PiechartSvg />
          {tabNames && <span>Investor</span>}
        </Tab>
      </TabList>
      <TabPanel>
        {/* <AccectPanel item={item} /> */}
        <ContactPanel
          card={card}
          assetIdList={assetIdList}
        />
      </TabPanel>
      <TabPanel>
        <BookmarkPanel />
      </TabPanel>
      <TabPanel>
        <NotebookPanel />
      </TabPanel>
      <TabPanel>
        <ComparisionPanel />
      </TabPanel>
      <TabPanel>
        <SharePanel />
      </TabPanel>
      <TabPanel>
        <InvestorFeedPanel />
      </TabPanel>
    </Tabs>
  );
}

const AssetDiscovery = () => {
  const [acquisitionActive, setAcquisitionActive] = useState(true);
  const [isAdvFilters, setIsAdvFilters] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAiSearch, setIsAiSearch] = useState(false);
  const selectedData = useSelector((state) => state.userInfo.userAssetData);
  const config = useAuthConfig();
  const dispatch = useDispatch();
  const {isAuthenticated, userInformation} = useSelector((state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    userInformation: state.auth.userInformation,
  }));

  let datacrowdfundingSlice = useSelector((state) => state.crowdfundingSlice.crowdfundingList);
  const {idToken} = useSelector((state) => state.auth);
  const [assetsList, setAssetsList] = useState([]);
  const [crownFundingList, setCrownFundingList] = useState();
  const [searchParam, setSearchParam] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  const handleClose = () => {
    setIsModalOpen(false);
  };
  // const handleViewCount = async () => {
  //   await axios.patch(`/api/assets/views/${selectedData?.assetId}`, null, config);
  // };

  const handleAiSearch = () => {
    setIsAiSearch(!isAiSearch);
    setIsAdvFilters(false);
  };


  // ---------------------------------FetchAssets Funding---------------------------//

  const fetchAssets = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("api/assets/AssetDiscovery/getDispositionsWithAssets", config);
      setAssetsList(response.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAssets();
  }, [idToken, searchParam?.length === 0 && searchParam]);

  useEffect(() => {
    (async () => {
      await fetchCrownFunding(idToken).then((res) => {
        setCrownFundingList(res?.data);
      });
    })();
  }, [dispatch]);

  const handleIncrement = async (preferredProperty) => {
    try {
      const response = await axios.patch("/api/assets/persona/increment_preference", {preferred_property: preferredProperty}, config);

      if (response.data.success) {
      }
    } catch (error) {}
  };

  const [loading, setLoading] = useState(true);
  const [syndicateData, setSyndicateData] = useState([]);

  const [tokenExpired, setTokenExpired] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  let [list, setList] = useState([]);

  useEffect(() => {
    setLoading(true);
    GetSyndicates(idToken)
      .then((res) => {
        setLoading(false);
        const {success, data} = res;
        if (!success) {
          if (data && data.message === "Token expired") {
            setTokenExpired(true);
          }
        } else {
          setSyndicateData(data || []);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [idToken]);

  // ---------------------------------Croud Funding---------------------------//

  const getCroudFunding = async () => {
    try {
      const response = await axios.get("api/crowdfunding", config);
      const newSyndicateData = response.data.data;
      dispatch(setCrowdfundingList(newSyndicateData));
    } catch (error) {}
  };

  useEffect(() => {
    getCroudFunding();
  }, [idToken]);

  const handleSelectCrowdfunding = (selectedItem) => {
    dispatch(selectCrowdfunding(selectedItem));
  };

  // Search data
  const handleSearchClick = async () => {
    try {
      const response = await axios.get(`/api/assets/search_assets?assetName=${searchTerm}`, config);

      if (response.data && response.data.length) {
        setList(response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleSearchClick();
  }, []);

 

  return (
    <div className={"AssetDiscovery " + (!acquisitionActive && " Crowdfunding")}>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
      >
        <AssetInformation2 selectedData={selectedData} />
      </Modal>
      <div className="AssetDiscovery-T">
        <AssetDiscoverySearch
          acquisitionActive={acquisitionActive}
          setAcquisitionActive={setAcquisitionActive}
          isAdvFilters={isAdvFilters}
          setIsAdvFilters={setIsAdvFilters}
          isAiSearch={isAiSearch}
          setIsAiSearch={setIsAiSearch}
          variant={"large"}
          handleAiSearch={handleAiSearch}
          setSearchParam={setSearchParam}
          searchParam={searchParam}
          userInformation={userInformation}
          handleSearchClick={handleSearchClick}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          syndicateData={syndicateData}
          list={list}
          userInformationrole={userInformation?.roleTypes}
        />
      </div>
      {acquisitionActive && (
        <div className="AssetDiscovery-B">
          <div className="AssetDiscovery-B-header">
            <h3>Recommended</h3>
          </div>

          <div className="AssetDiscovery">
            {isLoading && <LoaderOverlay desc="Please Wait Untill Map Load Or Refresh Page" />}
            <div className="AssetDiscovery-propertiesCon">
              {list?.map((item, idx) => (
                <div
                  className="AssetDiscovery-propertiesITEM"
                  key={idx}
                  onClick={() => {
                    dispatch(setUserAssetData(item));
                  }}
                >
                  <div
                    className="AssetDiscovery-propertiesITEM-L"
                    style={{display: "flex", gap: "30px", height: "270px"}}
                  >
                    <img
                      src={item?.assetsImages[0]}
                      alt=""
                      className="bulding_img"
                      loading="lazy"
                    />
                    <div
                      className="AssetDiscovery-propertiesITEM-M"
                      onClick={() => {
                        // handleViewCount(item.id);
                        handleIncrement(item?.assetType);
                        setIsModalOpen(true);
                      }}
                    >
                      <h1>{item?.assetName}</h1>
                      <h2>{item?.assetType}</h2>
                      <div className="fl">
                        <div className="sellChancesCon">
                          {item?.level || "HIGH LEVEL"}
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M8.25 12.75H9.75V8.25H8.25V12.75ZM9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM9 15C5.6925 15 3 12.3075 3 9C3 5.6925 5.6925 3 9 3C12.3075 3 15 5.6925 15 9C15 12.3075 12.3075 15 9 15ZM8.25 6.75H9.75V5.25H8.25V6.75Z"
                              fill="#9EA0A5"
                            />
                          </svg>
                        </div>
                        {item?.sponsored || "SPONSORED" && <div className="sponsoredBlock">Sponsored</div>}
                      </div>
                      <div className="onSaleCon">
                        <svg
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="4"
                            cy="4"
                            r="4"
                            fill="#009639"
                          />
                        </svg>
                        {item?.status || "On sale"}
                      </div>
                      <div className="onSaleCon">{item?.status}</div>
                      <div className="propertiesDetailLine">
                        <div className="blockOne">
                          <h1>${item?.assetValue?.toLocaleString("en-US")}</h1>
                          <h2>
                            $
                            {item?.propertyDetails?.totalUnits?.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}{" "}
                            100 per Unit
                          </h2>
                        </div>
                        <div className="allBlocks">
                          <div className="blockTwo first">
                            <img
                              src={commercialImg}
                              alt=""
                            />
                            <h2>{item?.assetType}</h2>
                          </div>
                          <div className="blockTwo">
                            <h1>{item?.assetSquareFeet || 2000}</h1>
                            <h2>Area, SF</h2>
                          </div>
                          <div className="blockTwo">
                            <h1>{item?.unitDuplex || 1}</h1>
                            <h2>Units Duplex</h2>
                          </div>
                        </div>
                      </div>

                      <div className="fl">
                        {item?.sold && (
                          <span>
                            Sold: <p>{item.sold}</p>
                          </span>
                        )}
                        {item?.debt && (
                          <span>
                            Debt: <p>{item.debt}</p>
                          </span>
                        )}
                      </div>

                      <h3>
                        {item?.assetName} <a href="#">More details.</a>
                      </h3>
                      <div className="fl">
                        <div className="viewCounter">
                          <img
                            src={clockImg}
                            alt=""
                          />
                          {moment(item?.date).format("DD-MM-YYYY")}
                        </div>
                        <div className="viewCounter">
                          <img
                            src={rewindImg}
                            alt=""
                          />
                          3 days ago
                        </div>
                        <div className="viewCounter">
                          <img
                            src={eyelImg}
                            alt=""
                          />
                          {item?.views}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="AssetDiscovery-propertiesITEM-R">
                    <div className="AssetDiscovery-propertiesITEM-R-L">
                      <Accectdata
                        data={item}
                        assetIdList={item}
                      />
                    </div>
                    <div
                      className="AssetDiscovery-propertiesITEM-R-R"
                      // onClick={() => showInMapClicked(item?.lat_and_long)}
                    >
                      {/* <Link>
                        <img
                          src={mapImg}
                          className="map_image"
                          alt=""
                        />
                      </Link> */}

                      {Array.isArray(item?.lat_and_long) && item.lat_and_long.length > 0 && Array.isArray(item.lat_and_long[0]) && item.lat_and_long[0].length >= 2 && (
                        <MiniMap
                          key={idx}
                          lat={item.lat_and_long[0][0] || 0}
                          long={item.lat_and_long[0][1] || 0}
                          style={{width: "207px", height: "270px"}}
                          className="Main-map-small"
                          mapOptions={{zoom: 14, scrollWheelZoom: true}}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {!acquisitionActive && (
        <div className="AssetDiscovery-B">
          <div className="AssetDiscovery-crowdfundingCon">
            {/* <PropertyCard
              acquisitionActive={acquisitionActive}
              setAcquisitionActive={setAcquisitionActive}
              syndicateData={syndicateData}
            /> */}
            {/* <PropertyCard />
            <PropertyCard />
            <PropertyCard />
            <PropertyCard />
            <PropertyCard />
            <PropertyCard /> */}

            {datacrowdfundingSlice?.length > 0 &&
              datacrowdfundingSlice?.map((syndicateData, index) => (
                <PropertyCard
                  syndicateData={syndicateData}
                  dispatch={dispatch}
                  handleSelectCrowdfunding={handleSelectCrowdfunding}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AssetDiscovery;

export function AssetDiscoverySearch({
  acquisitionActive,
  setAcquisitionActive,
  isAdvFilters,
  setIsAdvFilters,
  isAiSearch,
  setIsAiSearch,
  handleAiSearch,
  variant,
  setSearchParam,
  userInformation,
  handleSearchClick,
  searchTerm,
  setSearchTerm,
  syndicateData,
  list,
  userInformationrole
}) {
  const [isCard, setIsCard] = useState(false);
  const [isAiState, setIsAiState] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params.has("cf")) {
      setAcquisitionActive(false);
    } else {
      setAcquisitionActive(true);
    }
  }, [location.search]);

  let timeoutId;
  const handleSearchChange = (e) => {
    clearTimeout(timeoutId);
    const value = e.target.value;
    setSearchTerm(value);
    timeoutId = setTimeout(() => {
      setSearchParam(value);
    }, 100);
  };

  const inputRef = useRef(null);

  const [isPriceRangeOpen, setIsPriceRangeOpen] = useState(false);
  const [isAreaRangeOpen, setIsAreaRangeOpen] = useState(false);
  const [priceValue, setPriceValue] = useState();

  const handlePriceSliderChange = (e, newValue) => {
    setPriceValue(newValue);
  };

  const handlePriceInputChange = (e) => {
    setPriceValue(e.target.value === "" ? null : Number(e.target.value));
  };

  const [areaValue, setAreaValue] = useState();

  const handleAreaSliderChange = (e, newValue) => {
    setAreaValue(newValue);
  };

  const handleAreaInputChange = (e) => {
    setAreaValue(e.target.value === "" ? null : Number(e.target.value));
  };

  const handleAreaSelectorClick = () => {
    setIsAreaRangeOpen(!isAreaRangeOpen);
  };

  const handlePriceSelectorClick = () => {
    setIsPriceRangeOpen(!isPriceRangeOpen);
  };

  const handleButtonClick = () => {
    handleSearchClick(searchTerm);
  };

  return (
    <div className={`AssetDiscoverySearch ${variant}`}>
      <div className="AssetDiscoverySearch-T">
        <div style={{display: "flex"}}>
          {userInformation?.roleTypes === 2 && (
            <div
              onClick={() => {
                setAcquisitionActive(true);
                navigate({search: "?aq=true"});
              }}
              className={"AssetDiscoverySearch-tabItem " + (acquisitionActive ? "active" : "")}
            >
              <p>Acquisition</p>
            </div>
          )}

           <div
            onClick={() => {
              setAcquisitionActive(false);
              navigate({search: "?cf=true"});
            }}
            className={"AssetDiscoverySearch-tabItem " + (!acquisitionActive && " active ")}
          >
            <p>Crowdfunding</p>
          </div>

        </div>
        <div className="AssetDiscoverySearch-T-R">
          <button
            className="white_buttons"
            onClick={handleAiSearch}
          >
            {/* <BulbSvg /> */}
            <span>AI Search</span>
          </button>
          <button
            className="white_buttons"
            onClick={() => {
              setIsAdvFilters(!isAdvFilters);
              setIsAiSearch(false);
            }}
          >
            <SliderIcon />
            <span>Advanced Filters</span>
          </button>
        </div>
      </div>
      {acquisitionActive && (
        <div className="AssetDiscoverySearch-B">
          <div className="left">
            <div className="assetTypeItem">
              <div className="assetTypeItem-T">Asset Type</div>
              <div
                className="assetTypeItem-B"
                style={{display: "flex", alignItems: "center"}}
              >
                <div
                  className="search_bar"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "10px",
                  }}
                >
                  <InputBox
                    type="text"
                    className="search_input"
                    placeholder="Search..."
                    ref={inputRef}
                    onChange={handleSearchChange}
                    value={searchTerm}
                  />
                </div>
              </div>
            </div>
            <div className="assetTypeItem">
              <div className="assetTypeItem-T">Location</div>
              <div className="assetTypeItem-B">
                <InputBox
                  label="name"
                  type="text"
                  // svgPath={searchIcon}
                  width={"250px "}
                  placeholder={"City, State, Zip, "}
                />
              </div>
            </div>
            <div className="assetTypeItem">
              <div className="assetTypeItem-T">PRICE</div>
              <div className="assetTypeItem-B">
                {/* <InputBox
                  label="name"
                  type="text"
                  placeholder={"$ 0 – 0"}
                  width={"150px"}
                /> */}
                <div
                  className="priceRange range_selector_wrapper"
                  onClick={handlePriceSelectorClick}
                >
                  <p className="rangeBoxControl ">
                    $ <span>{priceValue}</span>
                  </p>
                  {isPriceRangeOpen === true ? (
                    <div
                      className="priceRangeSelector range_selector_box"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <div className="slider_box">
                        <Slider
                          value={typeof priceValue === "number" ? priceValue : 0}
                          onChange={handlePriceSliderChange}
                          aria-labelledby="input-slider"
                          step={10}
                          min={0}
                          max={1000000}
                        />
                        <Input
                          // onBlur={handleBlur}
                          value={priceValue}
                          size="small"
                          onChange={handlePriceInputChange}
                          inputProps={{
                            step: 1,
                            // min: 0,
                            max: 1000000,
                            type: "number",
                            "aria-labelledby": "input-slider",
                          }}
                        />{" "}
                      </div>
                      <button
                        onClick={() => {
                          setIsPriceRangeOpen(false);
                        }}
                      >
                        Close
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="assetTypeItem">
              <div className="assetTypeItem-T">Area</div>
              <div className="assetTypeItem-B">
                {/* <InputBox
                  label="name"
                  type="text"
                  placeholder={"10K – 20K"}
                  width={"150px"}
                  svgPath={SFICOn}
                /> */}
                <div
                  className="areaRange range_selector_wrapper"
                  onClick={handleAreaSelectorClick}
                >
                  <p className="rangeBoxControl ">
                    <span>{areaValue}</span> sq.f
                  </p>
                  {isAreaRangeOpen === true ? (
                    <div
                      className="areaRangeSelector range_selector_box"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <div className="slider_box">
                        <Slider
                          value={typeof areaValue === "number" ? areaValue : 0}
                          onChange={handleAreaSliderChange}
                          aria-labelledby="input-slider"
                          step={10}
                          min={0}
                          max={1000000}
                        />
                        <Input
                          // onBlur={handleBlur}
                          value={areaValue}
                          size="small"
                          onChange={handleAreaInputChange}
                          inputProps={{
                            step: 1,
                            // min: 0,
                            max: 1000000,
                            type: "number",
                            "aria-labelledby": "input-slider",
                          }}
                        />
                      </div>
                      <button
                        onClick={() => {
                          setIsAreaRangeOpen(false);
                        }}
                      >
                        Close
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            {variant === "large" ? (
              <div className="assetTypeItem">
                <div className="assetTypeItem-T performance">
                  Performance
                  <div style={{marginLeft: "105px"}}></div>
                </div>
                <div className="assetTypeItem-B">
                  <div
                    className="performance_inputs"
                    style={{display: "flex", width: "250px"}}
                  >
                    <InputBox
                      label="name"
                      type="text"
                      placeholder={"Cap Rate 10%"}
                    />
                    <InputBox
                      label="name"
                      type="text"
                      placeholder={"CoC 10%"}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="assetTypeItem">
                <div className="buttons_wrapper">
                  <div style={{position: "relative"}}>
                    <button
                      className={isCard === 1 && "activeButton"}
                      onClick={() => {
                        setIsCard((prevState) => (prevState !== 1 ? 1 : false));
                      }}
                    ></button>
                    {isCard === 1 && (
                      <div className="options_card">
                        <div className="dropDownOuterCon ">
                          <p style={{marginBottom: "0px"}}> Cap Rate </p>
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <span>9</span>
                              <p>%</p>
                            </div>
                            <Slider
                              defaultValue={30}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              onChange={(event) => {
                                // setPercentOwnership(event.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="dropDownOuterCon ">
                          <p style={{marginBottom: "0px"}}> Cash-on-cash Return</p>
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <span>9</span>
                              <p>%</p>
                            </div>
                            <Slider
                              defaultValue={30}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              onChange={(event) => {
                                // setPercentOwnership(event.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div style={{position: "relative"}}>
                    <button
                      className={isCard === 2 && "activeButton"}
                      onClick={() => {
                        setIsCard((prevState) => (prevState !== 2 ? 2 : false));
                      }}
                    ></button>
                    {isCard === 2 && (
                      <div className="options_card">
                        <div className="switch_wrapper">
                          <FormControlLabel
                            control={
                              <IOSSwitch
                                sx={{m: 1}}
                                defaultChecked={true}
                                // onChange={() =>
                                //   setPrice((prev) => ({
                                //     ...prev,
                                //     any: !prev.any,
                                //   }))
                                // }
                              />
                            }
                            label="Show Off-Market"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                .
              </div>
            )}
          </div>
          <div className="right">
            <div className="top">
              <p>
                <span> {list?.length} </span>Assets found
              </p>
              {variant === "medium" ? (
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.0751 1.58516L6.3251 6.33416L1.5711 11.1772C1.20292 11.5539 0.997773 12.0604 1.00002 12.5871C1.00226 13.1139 1.21172 13.6186 1.5831 13.9922L5.8821 18.2912C5.97459 18.3846 6.08472 18.4587 6.20609 18.5091C6.32746 18.5596 6.45765 18.5854 6.5891 18.5852H18.5901V16.5852H11.6321L18.8541 9.36316C19.6341 8.58316 19.6341 7.31516 18.8541 6.53516L13.9031 1.58516C13.5279 1.21046 13.0193 1 12.4891 1C11.9589 1 11.4503 1.21046 11.0751 1.58516ZM7.0031 16.5852L2.9981 12.5792L7.7461 7.74216L8.4891 6.99916L13.4391 11.9492L8.8821 16.5062C8.85749 16.531 8.83445 16.5574 8.8131 16.5852H7.0031Z"
                    fill="#9EA0A5"
                  />
                </svg>
              ) : (
                ""
              )}
            </div>
            <div className="bottom">
              {variant === "large" ? (
                <div className="switch_wrapper">
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{m: 1}}
                        defaultChecked={true}
                        // color=""
                        // onChange={() =>
                        //   setPrice((prev) => ({
                        //     ...prev,
                        //     any: !prev.any,
                        //   }))
                        // }
                      />
                    }
                    label="Off-market"
                  />
                </div>
              ) : (
                ""
              )}
              {variant === "large" ? (
                <div className="searchButton">
                  <PrimaryButtonSvgFirst
                    svgPath={searchWhite}
                    svgWidth={"20px"}
                    svgHeight={"20px"}
                    name={"Search"}
                    classNames={"search_button"}
                    onclick={handleButtonClick}
                  />
                </div>
              ) : (
                <div className="searchButton">
                  <PrimaryButtonSvgFirst
                    svgPath={searchWhite}
                    svgWidth={"20px"}
                    svgHeight={"20px"}
                    classNames={"search_button"}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {!acquisitionActive &&
        (variant === "large" ? (
          <div className="AssetDiscoverySearch-B crowdfunding">
            <div className="left">
              <div className="assetTypeItem">
                <div className="assetTypeItem-T">Asset Type</div>
                <div className="assetTypeItem-B">
                  <DropDown
                    selectedData={["commercial"]}
                    margin={"10px 0px 0px 0px"}
                    width={"170px"}
                    label="name"
                    type="text"
                  />
                </div>
              </div>
              <div className="assetTypeItem">
                <div className="assetTypeItem-T">IRR</div>
                <div className="assetTypeItem-B">
                  <InputBox
                    label="name"
                    type="text"
                    width={"100px"}
                    placeholder={"10 – 15   %"}
                  />
                </div>
              </div>
              <div className="assetTypeItem">
                <div className="assetTypeItem-T">Equity Multiple</div>
                <div className="assetTypeItem-B">
                  <InputBox
                    label="name"
                    type="text"
                    width={"100px"}
                    placeholder={"2.0x – 3.0x"}
                  />
                </div>
              </div>
              <div className="assetTypeItem">
                <div className="assetTypeItem-T">Cash Yield, AVG</div>
                <div className="assetTypeItem-B">
                  <InputBox
                    label="name"
                    type="text"
                    width={"100px"}
                    placeholder={"7 – 10   %"}
                  />
                </div>
              </div>
              <div className="assetTypeItem">
                <div className="assetTypeItem-T">STRUCTURE</div>
                <div className="assetTypeItem-B">
                  <DropDown
                    selectedData={["Preferred Equity"]}
                    margin={"10px 0px 0px 0px"}
                    width={"170px"}
                    label="name"
                    type="text"
                  />
                </div>
              </div>
              <div className="assetTypeItem">
                <div className="assetTypeItem-T">Minimum Investment</div>
                <div className="assetTypeItem-B">
                  <InputBox
                    label="name"
                    type="text"
                    placeholder={"up to  $25,000"}
                    width={"130px"}
                  />
                </div>
              </div>
              <div className="assetTypeItem">
                <div className="assetTypeItem-T">Investment period</div>
                <div className="assetTypeItem-B">
                  <InputBox
                    label="name"
                    type="text"
                    placeholder={"3 – 5    "}
                    width={"130px"}
                  />
                </div>
              </div>
            </div>
            <div className="right">
              <div className="top">
                <p>
                  <span> {syndicateData?.length} </span>Offerings found
                </p>
              </div>
              <div className="bottom">
                <div className="switch_wrapper">
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{m: 1}}
                        defaultChecked={true}
                        // onChange={() =>
                        //   setPrice((prev) => ({
                        //     ...prev,
                        //     any: !prev.any,
                        //   }))
                        // }
                      />
                    }
                    label="Accredited only"
                  />
                </div>
                <div className="searchButton">
                  {/* <Link to="/asset-search"> */}
                  <PrimaryButtonSvgFirst
                    svgPath={searchWhite}
                    svgWidth={"20px"}
                    svgHeight={"20px"}
                    name={"Search"}
                    classNames={"search_button"}
                  />
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="AssetDiscoverySearch-B">
            <div className="left">
              <div className="assetTypeItem">
                <div className="assetTypeItem-T">Asset Type</div>
                <div className="assetTypeItem-B">
                  <DropDown
                    selectedData={["commercial"]}
                    margin={"10px 0px 0px 0px"}
                    width={"170px"}
                    label="name"
                    type="text"
                  />
                </div>
              </div>
              <div className="assetTypeItem">
                <div className="assetTypeItem-T">Location</div>
                <div className="assetTypeItem-B">
                  <InputBox
                    label="name"
                    type="text"
                    // svgPath={searchIcon}
                    width={"250px "}
                    placeholder={"City, Address, Zip, "}
                  />
                </div>
              </div>
              <div className="assetTypeItem">
                <div className="assetTypeItem-T">PRICE</div>
                <div className="assetTypeItem-B">
                  <InputBox
                    label="name"
                    type="text"
                    placeholder={"$ 0 – 0"}
                    width={"150px"}
                  />
                </div>
              </div>
              <div className="assetTypeItem">
                <div className="assetTypeItem-T">Area</div>
                <div className="assetTypeItem-B">
                  <InputBox
                    label="name"
                    type="text"
                    placeholder={"10K – 20K"}
                    width={"150px"}
                    svgPath={SFICOn}
                  />
                </div>
              </div>
              {variant === "large" ? (
                <div className="assetTypeItem">
                  <div className="assetTypeItem-T">
                    Performance
                    <div style={{marginLeft: "105px"}}></div>
                  </div>
                  <div className="assetTypeItem-B">
                    <div
                      className="performance_inputs"
                      style={{display: "flex", width: "250px"}}
                    >
                      <InputBox
                        label="name"
                        type="text"
                        placeholder={"Cap Rate 10%"}
                      />
                      <InputBox
                        label="name"
                        type="text"
                        placeholder={"CoC 10%"}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="assetTypeItem">
                  <div className="buttons_wrapper">
                    <div style={{position: "relative"}}>
                      <button
                        className={isCard === 1 && "activeButton"}
                        onClick={() => {
                          setIsCard((prevState) => (prevState !== 1 ? 1 : false));
                        }}
                      ></button>
                      {isCard === 1 && (
                        <div className="options_card">
                          <div className="dropDownOuterCon ">
                            <p style={{marginBottom: "0px"}}> Cap Rate </p>
                            <div className="slider_container">
                              <div className="top_div inputFlexLine">
                                <span>9</span>
                                <p>%</p>
                              </div>
                              <Slider
                                defaultValue={30}
                                aria-label="Default"
                                valueLabelDisplay="auto"
                                onChange={(event) => {
                                  // setPercentOwnership(event.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="dropDownOuterCon ">
                            <p style={{marginBottom: "0px"}}> Cash-on-cash Return</p>
                            <div className="slider_container">
                              <div className="top_div inputFlexLine">
                                <span>9</span>
                                <p>%</p>
                              </div>
                              <Slider
                                defaultValue={30}
                                aria-label="Default"
                                valueLabelDisplay="auto"
                                onChange={(event) => {
                                  // setPercentOwnership(event.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div style={{position: "relative"}}>
                      <button
                        className={isCard === 2 && "activeButton"}
                        onClick={() => {
                          setIsCard((prevState) => (prevState !== 2 ? 2 : false));
                        }}
                      ></button>
                      {isCard === 2 && (
                        <div className="options_card">
                          <div className="switch_wrapper">
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  sx={{m: 1}}
                                  defaultChecked={true}
                                  // onChange={() =>
                                  //   setPrice((prev) => ({
                                  //     ...prev,
                                  //     any: !prev.any,
                                  //   }))
                                  // }
                                />
                              }
                              label="Show Off-Market"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  .
                </div>
              )}
            </div>
            <div className="right">
              <div className="top">
                <p>
                  <span> 421,458 </span>Assets found
                </p>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.0751 1.58516L6.3251 6.33416L1.5711 11.1772C1.20292 11.5539 0.997773 12.0604 1.00002 12.5871C1.00226 13.1139 1.21172 13.6186 1.5831 13.9922L5.8821 18.2912C5.97459 18.3846 6.08472 18.4587 6.20609 18.5091C6.32746 18.5596 6.45765 18.5854 6.5891 18.5852H18.5901V16.5852H11.6321L18.8541 9.36316C19.6341 8.58316 19.6341 7.31516 18.8541 6.53516L13.9031 1.58516C13.5279 1.21046 13.0193 1 12.4891 1C11.9589 1 11.4503 1.21046 11.0751 1.58516ZM7.0031 16.5852L2.9981 12.5792L7.7461 7.74216L8.4891 6.99916L13.4391 11.9492L8.8821 16.5062C8.85749 16.531 8.83445 16.5574 8.8131 16.5852H7.0031Z"
                    fill="#9EA0A5"
                  />
                </svg>
              </div>
              <div className="bottom">
                {/* <div className="switch_wrapper">
               <FormControlLabel
                 control={
                   <IOSSwitch
                     sx={{m: 1}}
                     defaultChecked={true}
                     // onChange={() =>
                     //   setPrice((prev) => ({
                     //     ...prev,
                     //     any: !prev.any,
                     //   }))
                     // }
                   />
                 }
                 label="Off-market"
               />
             </div> */}
                {variant === "large" ? (
                  <div className="searchButton">
                    <Link to="/asset-search">
                      <PrimaryButtonSvgFirst
                        svgPath={searchWhite}
                        svgWidth={"20px"}
                        svgHeight={"20px"}
                        name={"Search"}
                        classNames={"search_button"}
                      />
                    </Link>
                  </div>
                ) : (
                  <div className="searchButton">
                    <PrimaryButtonSvgFirst
                      svgPath={searchWhite}
                      svgWidth={"20px"}
                      svgHeight={"20px"}
                      classNames={"search_button"}
                      onclick={handleButtonClick}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      {isAdvFilters && (
        <AdvancedFilters
          onClick={() => {
            setIsAdvFilters(false);
          }}
        />
      )}
      {isAiSearch && (
        <AiSearch
          setIsAiState={setIsAiState}
          isAiSearch={isAiSearch}
          handleAiSearch={handleAiSearch}
        />
      )}
      {isAiSearch && isAiState === 1 && (
        <AiSearch1
          setIsAiState={setIsAiState}
          isAiState={isAiState}
          handleAiSearch={handleAiSearch}
        />
      )}
      {isAiSearch && isAiState === 2 && (
        <AiSearch2
          setIsAiState={setIsAiState}
          isAiState={isAiState}
          handleAiSearch={handleAiSearch}
        />
      )}
    </div>
  );
}

function PropertyCard({acquisitionActive, setAcquisitionActive, syndicateData, card, dispatch, handleSelectCrowdfunding}) {
  const [isOpen, setIsOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const calculateTimeLeft = () => {
    const now = new Date();
    const difference = new Date(syndicateData?.endDate) - now;

    if (difference > 0) {
      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Clean up the timer interval on component unmount
    return () => clearInterval(timer);
  }, [syndicateData?.endDate]);

  const {days, hours, minutes, seconds} = timeLeft;

  return (
    <div className="property_card_wrapper">
      <div className="property_card">
        <div className="property_card-T">
          <div className="photo_wrapper">
            <div className="tag">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.2 6.4H8.8V4.8H7.2V6.4ZM7.2 9.6H8.8V8H7.2V9.6ZM8.8 14.4V11.2H7.2V14.4H4.8V4H7.2H8.8H11.2V14.4H8.8ZM14.4 12.8V14.4H12.8V2.4H11.2H8.8V0H7.2V2.4H4.8H3.2V14.4H1.6V12.8H0V14.4V16H3.2H4.8H11.2H12.8H16V14.4V12.8H14.4Z"
                  fill="white"
                  fill-opacity="0.01"
                />
                <mask
                  id="mask0_13601_124259"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="16"
                  height="16"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.2 6.4H8.8V4.8H7.2V6.4ZM7.2 9.6H8.8V8H7.2V9.6ZM8.8 14.4V11.2H7.2V14.4H4.8V4H7.2H8.8H11.2V14.4H8.8ZM14.4 12.8V14.4H12.8V2.4H11.2H8.8V0H7.2V2.4H4.8H3.2V14.4H1.6V12.8H0V14.4V16H3.2H4.8H11.2H12.8H16V14.4V12.8H14.4Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_13601_124259)">
                  <rect
                    x="-0.800003"
                    y="-0.800781"
                    width="17.6"
                    height="17.6"
                    fill="#6B6C6F"
                  />
                </g>
              </svg>
              <p>{syndicateData?.asset_id?.assetType}</p>
            </div>
            {/* <ThumbsSlider className="crownfunding_slider" /> */}
            <img
              className="photo_card"
              style={{marginTop: "10px", objectFit: "cover"}}
              src={syndicateData?.assetDetails?.assetsImages[0]}
              alt="img"
            />
          </div>
          <div className="text_wrapper">
            <h3>{syndicateData?.assetDetails?.assetName}</h3>
            <div style={{display: "flex"}}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.59999 5.7752C9.59999 6.6592 8.88399 7.3752 7.99999 7.3752C7.11599 7.3752 6.39999 6.6592 6.39999 5.7752C6.39999 4.8912 7.11599 4.1752 7.99999 4.1752C8.88399 4.1752 9.59999 4.8912 9.59999 5.7752ZM7.99999 14.4C6.54239 14.4 3.99999 8.808 3.99999 5.6C3.99999 3.3944 5.79439 1.6 7.99999 1.6C10.2056 1.6 12 3.3944 12 5.6C12 8.808 9.45759 14.4 7.99999 14.4ZM7.99999 0C4.90719 0 2.39999 2.5072 2.39999 5.6C2.39999 8.6928 4.90719 16 7.99999 16C11.0928 16 13.6 8.6928 13.6 5.6C13.6 2.5072 11.0928 0 7.99999 0Z"
                  fill="white"
                  fill-opacity="0.01"
                />
                <mask
                  id="mask0_13614_124294"
                  maskUnits="userSpaceOnUse"
                  x="2"
                  y="0"
                  width="12"
                  height="16"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.59999 5.7752C9.59999 6.6592 8.88399 7.3752 7.99999 7.3752C7.11599 7.3752 6.39999 6.6592 6.39999 5.7752C6.39999 4.8912 7.11599 4.1752 7.99999 4.1752C8.88399 4.1752 9.59999 4.8912 9.59999 5.7752ZM7.99999 14.4C6.54239 14.4 3.99999 8.808 3.99999 5.6C3.99999 3.3944 5.79439 1.6 7.99999 1.6C10.2056 1.6 12 3.3944 12 5.6C12 8.808 9.45759 14.4 7.99999 14.4ZM7.99999 0C4.90719 0 2.39999 2.5072 2.39999 5.6C2.39999 8.6928 4.90719 16 7.99999 16C11.0928 16 13.6 8.6928 13.6 5.6C13.6 2.5072 11.0928 0 7.99999 0Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_13614_124294)">
                  <rect
                    x="-0.800003"
                    y="-0.800781"
                    width="17.6"
                    height="17.6"
                    fill="#9EA0A5"
                  />
                </g>
              </svg>
              <p>{syndicateData?.asset_id?.assetsAddress}</p>
            </div>
          </div>
        </div>

        {/* Timer */}
        <div className="property_card-M">
          <div className="timer">
            <div className="left">
              <p>Offer Available for:</p>
            </div>
            <div className="right">
              <div className="time">
                <p>{String(days).padStart(2, "0")}</p>
                <span>days</span>
              </div>
              <div className="time">
                <p>{String(hours).padStart(2, "0")}</p>
                <span>Hours</span>
              </div>
              <div className="time">
                <p>{String(minutes).padStart(2, "0")}</p>
                <span>Minutes</span>
              </div>
              <div className="time">
                <p>{String(seconds).padStart(2, "0")}</p>
                <span>Seconds</span>
              </div>
            </div>
          </div>

          {showMore && (
            <div className="boxes_wrapper">
              <div className="boxes1">
                <div className="box">
                  Yield <span>{syndicateData?.general?.Yield}</span>
                </div>
                <div className="box">
                  LTV <span>{syndicateData?.general?.LTV}</span>
                </div>
                <div className="box">
                  Hold Period <span>{syndicateData?.general?.Hold_Period}</span>
                </div>
                <div className="box">
                  Amount <span>{formatUSD(syndicateData?.general?.Amount)}</span>
                </div>
              </div>
              <div className="boxes2">
                <div className="box">
                  Minimum Investment <span>{formatUSD(syndicateData?.general?.Minimum_Investment)}</span>
                </div>
                <div className="box">
                  redemption period <span>{syndicateData?.general?.Redemption_Period}</span>
                </div>
                <div className="box">
                  Strategy <span>{syndicateData?.general?.Strategy}</span>
                </div>
              </div>
              <div className="boxes3">
                <div className="box">
                  Area, SF <span>{syndicateData?.general?.Area}</span> Duplex
                </div>
                <div className="box">
                  Appraisal Value <span>{formatUSD(syndicateData?.general?.Appraisal_Value)}</span> from {moment(card?.property?.appraisal_date).format("YYYY-MM-DD")}
                </div>
              </div>
            </div>
          )}

          <div className="progressbar_wrapper">
            <div className="header">
              <p>Raised</p> <span>{card?.raised}</span>
            </div>
            <ProgressBar
              bgColor="#7d55c7"
              completed={parseInt(card?.raised, 10)}
              isLabelVisible={false}
              height="8px"
            />
          </div>
          {/* <div className="count-display">
            <p>Number of boxes: {boxCount}</p>
          </div> */}
        </div>
        <div className="property_card-B">
          <div
            className="buttons"
            onClick={() => setIsOpen(true)}
          >
            <div className="button">
              <PhoneSvg /> <span>Contacts</span>
            </div>
            <div className="button">
              {" "}
              <BookmarkSvg /> <span>Save</span>
            </div>
            <div className="button">
              {" "}
              <NotebookSvg />
              <span>Add Note</span>
            </div>
            <div className="button">
              {" "}
              <CompareSvg /> <span>Compare</span>
            </div>
            <div className="button">
              {" "}
              <ShareSvg /> <span>Share</span>
            </div>
          </div>
          <div className="stats">
            <div className="date">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.4 8C14.4 4.4712 11.5288 1.6 8 1.6C4.4712 1.6 1.6 4.4712 1.6 8C1.6 11.5288 4.4712 14.4 8 14.4C11.5288 14.4 14.4 11.5288 14.4 8ZM16 8C16 12.4184 12.4184 16 8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8ZM12.8 8.8C12.8 9.2416 12.4416 9.6 12 9.6H8.8C7.916 9.6 7.2 8.884 7.2 8V4C7.2 3.5584 7.5584 3.2 8 3.2C8.4416 3.2 8.8 3.5584 8.8 4V7.2C8.8 7.64 9.16 8 9.6 8H12C12.4416 8 12.8 8.3584 12.8 8.8Z"
                  fill="white"
                  fill-opacity="0.01"
                />
                <mask
                  id="mask0_13625_124448"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="16"
                  height="16"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.4 8C14.4 4.4712 11.5288 1.6 8 1.6C4.4712 1.6 1.6 4.4712 1.6 8C1.6 11.5288 4.4712 14.4 8 14.4C11.5288 14.4 14.4 11.5288 14.4 8ZM16 8C16 12.4184 12.4184 16 8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8ZM12.8 8.8C12.8 9.2416 12.4416 9.6 12 9.6H8.8C7.916 9.6 7.2 8.884 7.2 8V4C7.2 3.5584 7.5584 3.2 8 3.2C8.4416 3.2 8.8 3.5584 8.8 4V7.2C8.8 7.64 9.16 8 9.6 8H12C12.4416 8 12.8 8.3584 12.8 8.8Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_13625_124448)">
                  <rect
                    x="-0.800003"
                    y="-0.800781"
                    width="17.6"
                    height="17.6"
                    fill="#9EA0A5"
                  />
                </g>
              </svg>
              date:{" "}
              {new Date(syndicateData?.assetDetails?.date).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </div>
            <div className="views">
              <svg
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="path-1-outside-1_13625_124461"
                  maskUnits="userSpaceOnUse"
                  x="-0.333336"
                  y="1"
                  width="19"
                  height="14"
                  fill="black"
                >
                  <rect
                    fill="white"
                    x="-0.333336"
                    y="1"
                    width="19"
                    height="14"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9 3C5.66666 3 2.82 5.07333 1.66666 8C2.82 10.9267 5.66666 13 9 13C12.3333 13 15.18 10.9267 16.3333 8C15.18 5.07333 12.3333 3 9 3ZM9 11.3333C7.16 11.3333 5.66666 9.84 5.66666 8C5.66666 6.16 7.16 4.66667 9 4.66667C10.84 4.66667 12.3333 6.16 12.3333 8C12.3333 9.84 10.84 11.3333 9 11.3333ZM9 6C7.89333 6 7 6.89333 7 8C7 9.10667 7.89333 10 9 10C10.1067 10 11 9.10667 11 8C11 6.89333 10.1067 6 9 6Z"
                  />
                </mask>
                <path
                  d="M1.66666 8L0.271117 7.45005L0.0543929 8L0.271117 8.54995L1.66666 8ZM16.3333 8L17.7289 8.54995L17.9456 8L17.7289 7.45005L16.3333 8ZM9 1.5C5.02758 1.5 1.64183 3.97176 0.271117 7.45005L3.06221 8.54995C3.99816 6.17491 6.30575 4.5 9 4.5V1.5ZM0.271117 8.54995C1.64183 12.0282 5.02758 14.5 9 14.5V11.5C6.30575 11.5 3.99816 9.82509 3.06221 7.45005L0.271117 8.54995ZM9 14.5C12.9724 14.5 16.3582 12.0282 17.7289 8.54995L14.9378 7.45005C14.0018 9.82509 11.6942 11.5 9 11.5V14.5ZM17.7289 7.45005C16.3582 3.97176 12.9724 1.5 9 1.5V4.5C11.6942 4.5 14.0018 6.17491 14.9378 8.54995L17.7289 7.45005ZM9 9.83333C7.98842 9.83333 7.16666 9.01157 7.16666 8H4.16666C4.16666 10.6684 6.33157 12.8333 9 12.8333V9.83333ZM7.16666 8C7.16666 6.98843 7.98842 6.16667 9 6.16667V3.16667C6.33157 3.16667 4.16666 5.33157 4.16666 8H7.16666ZM9 6.16667C10.0116 6.16667 10.8333 6.98843 10.8333 8H13.8333C13.8333 5.33157 11.6684 3.16667 9 3.16667V6.16667ZM10.8333 8C10.8333 9.01157 10.0116 9.83333 9 9.83333V12.8333C11.6684 12.8333 13.8333 10.6684 13.8333 8H10.8333ZM9 4.5C7.0649 4.5 5.5 6.06491 5.5 8H8.5C8.5 7.72176 8.72176 7.5 9 7.5V4.5ZM5.5 8C5.5 9.93509 7.0649 11.5 9 11.5V8.5C8.72176 8.5 8.5 8.27824 8.5 8H5.5ZM9 11.5C10.9351 11.5 12.5 9.93509 12.5 8H9.5C9.5 8.27824 9.27824 8.5 9 8.5V11.5ZM12.5 8C12.5 6.06491 10.9351 4.5 9 4.5V7.5C9.27824 7.5 9.5 7.72176 9.5 8H12.5Z"
                  fill="#9EA0A5"
                  mask="url(#path-1-outside-1_13625_124461)"
                />
              </svg>
              {card?.property?.total_views?.total} (<span>{syndicateData?.assetDetails?.views}</span>)
            </div>
            <div className="active">
              <div className="dotgreen"></div>
              Active
            </div>
          </div>
        </div>
      </div>
      <div className="btn_options">
        {/* <button onClick={handleDetailsClick}>{showMore === true ? "Collapse" : "Expand"}</button> */}
        <Link
          to={{
            pathname: "/crowdfunding",
            state: {
              acquisitionActive: acquisitionActive,
              setAcquisitionActive: setAcquisitionActive,
            },
          }}
          onClick={() => {
            dispatch(setChatId(syndicateData?.assetDetails?.addedByEmail));
            dispatch(setAssetName(syndicateData?.assetDetails?.assetName));
            dispatch(setAssetId(syndicateData?.assetDetails?._id));
            handleSelectCrowdfunding(syndicateData);
          }}
        >
          <div className="view_details_button">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_13628_124239)">
                <path
                  d="M17.2507 7.28585C13.2341 3.2693 6.69932 3.2693 2.68277 7.28585L0 9.96829L2.74932 12.7176C4.75743 14.7257 7.39527 15.7301 10.0334 15.7301C12.6716 15.7301 15.3091 14.7261 17.3176 12.7176L20.0003 10.0348L17.2507 7.28585ZM16.8395 12.2399C13.0865 15.9926 6.98007 15.9926 3.22703 12.2399L0.955405 9.96829L3.16047 7.76322C6.91351 4.01052 13.0199 4.01052 16.773 7.76322L19.0446 10.0348L16.8395 12.2399Z"
                  fill="white"
                />
                <path
                  d="M9.6527 7.28906C8.34864 7.28906 7.28783 8.34987 7.28783 9.65393C7.28783 9.84041 7.43919 9.99177 7.62567 9.99177C7.81216 9.99177 7.96351 9.84041 7.96351 9.65393C7.96351 8.72251 8.72128 7.96474 9.6527 7.96474C9.83919 7.96474 9.99054 7.81339 9.99054 7.6269C9.99054 7.44041 9.83952 7.28906 9.6527 7.28906Z"
                  fill="white"
                />
                <path
                  d="M9.99055 5.60156C7.56893 5.60156 5.59866 7.57183 5.59866 9.99345C5.59866 12.4151 7.56893 14.3853 9.99055 14.3853C12.4122 14.3853 14.3824 12.4151 14.3824 9.99345C14.3824 7.57183 12.4125 5.60156 9.99055 5.60156ZM9.99055 13.7097C7.94156 13.7097 6.27433 12.0424 6.27433 9.99345C6.27433 7.94447 7.94156 6.27724 9.99055 6.27724C12.0395 6.27724 13.7068 7.94447 13.7068 9.99345C13.7068 12.0424 12.0399 13.7097 9.99055 13.7097Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_13628_124239">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>

            <p>View details</p>
          </div>
        </Link>
      </div>
      {isOpen && (
        <div className="floatCard_wrapper">
          <PropertyCardMini card={card} />
          <button onClick={() => setIsOpen(false)}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_13700_30685)">
                <g filter="url(#filter0_di_13700_30685)">
                  <rect
                    width="24"
                    height="24"
                    rx="12"
                    fill="url(#paint0_linear_13700_30685)"
                  />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="23"
                    height="23"
                    rx="11.5"
                    stroke="#D8DCE6"
                  />
                </g>
              </g>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.4142 12.0009L16.9497 8.46537L15.5355 7.05116L12 10.5867L8.46447 7.05116L7.05025 8.46537L10.5858 12.0009L7.05025 15.5364L8.46447 16.9507L12 13.4151L15.5355 16.9507L16.9497 15.5364L13.4142 12.0009Z"
                fill="white"
                fill-opacity="0.01"
              />
              <mask
                id="mask0_13700_30685"
                maskUnits="userSpaceOnUse"
                x="7"
                y="7"
                width="10"
                height="10"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.4142 12.0009L16.9497 8.46537L15.5355 7.05116L12 10.5867L8.46447 7.05116L7.05025 8.46537L10.5858 12.0009L7.05025 15.5364L8.46447 16.9507L12 13.4151L15.5355 16.9507L16.9497 15.5364L13.4142 12.0009Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_13700_30685)">
                <rect
                  x="3"
                  y="3"
                  width="18"
                  height="18"
                  fill="#6B6C6F"
                />
              </g>
              <defs>
                <filter
                  id="filter0_di_13700_30685"
                  x="-1"
                  y="0"
                  width="26"
                  height="26"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood
                    flood-opacity="0"
                    result="BackgroundImageFix"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="1" />
                  <feGaussianBlur stdDeviation="0.5" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.0863 0 0 0 0 0.1137 0 0 0 0 0.1451 0 0 0 0.05 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_13700_30685"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_13700_30685"
                    result="shape"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="2" />
                  <feComposite
                    in2="hardAlpha"
                    operator="arithmetic"
                    k2="-1"
                    k3="1"
                  />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.05 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="shape"
                    result="effect2_innerShadow_13700_30685"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear_13700_30685"
                  x1="24"
                  y1="24"
                  x2="24"
                  y2="0"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#F6F7F9" />
                  <stop
                    offset="1"
                    stop-color="white"
                  />
                </linearGradient>
                <clipPath id="clip0_13700_30685">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
      )}
    </div>
  );
}

export function PropertyCardMini({collapsed, onClick, card, assetIdList}) {
  const [value, setValue] = useState(collapsed);
  // useEffect(() => {
  //   setValue(collapsed);
  // }, [value]);
  return (
    <div className="property_card_mini_wrapper">
      <div className="property_card_mini">
        <div className="property_card_mini-T">
          <div className="photo_wrapper">
            <img
              src={Propcard1}
              alt=""
            />
          </div>
          <div className="text_wrapper">
            <h3>{card?.property?.name}</h3>
            <div style={{display: "flex"}}>
              <p>{card?.property?.address}</p>
            </div>
            <div className="price_wrapper">
              <h2>$1,290,000</h2> <span>{card?.property?.status}</span>
            </div>
          </div>
        </div>
        <div className="property_card_mini-M">
          <div className="stats">
            <div className="date">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.4 8C14.4 4.4712 11.5288 1.6 8 1.6C4.4712 1.6 1.6 4.4712 1.6 8C1.6 11.5288 4.4712 14.4 8 14.4C11.5288 14.4 14.4 11.5288 14.4 8ZM16 8C16 12.4184 12.4184 16 8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8ZM12.8 8.8C12.8 9.2416 12.4416 9.6 12 9.6H8.8C7.916 9.6 7.2 8.884 7.2 8V4C7.2 3.5584 7.5584 3.2 8 3.2C8.4416 3.2 8.8 3.5584 8.8 4V7.2C8.8 7.64 9.16 8 9.6 8H12C12.4416 8 12.8 8.3584 12.8 8.8Z"
                  fill="white"
                  fill-opacity="0.01"
                />
                <mask
                  id="mask0_13625_124448"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="16"
                  height="16"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.4 8C14.4 4.4712 11.5288 1.6 8 1.6C4.4712 1.6 1.6 4.4712 1.6 8C1.6 11.5288 4.4712 14.4 8 14.4C11.5288 14.4 14.4 11.5288 14.4 8ZM16 8C16 12.4184 12.4184 16 8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8ZM12.8 8.8C12.8 9.2416 12.4416 9.6 12 9.6H8.8C7.916 9.6 7.2 8.884 7.2 8V4C7.2 3.5584 7.5584 3.2 8 3.2C8.4416 3.2 8.8 3.5584 8.8 4V7.2C8.8 7.64 9.16 8 9.6 8H12C12.4416 8 12.8 8.3584 12.8 8.8Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_13625_124448)">
                  <rect
                    x="-0.800003"
                    y="-0.800781"
                    width="17.6"
                    height="17.6"
                    fill="#9EA0A5"
                  />
                </g>
              </svg>
              {moment(card?.createdAt).format("MMM. DD, YYYY")}
            </div>
            <div className="views">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.5 8.43333H11.85L14.1333 6.08333C11.8583 3.83333 8.17496 3.75 5.89996 6C3.62496 8.25833 3.62496 11.9 5.89996 14.1583C8.17496 16.4167 11.8583 16.4167 14.1333 14.1583C15.2666 13.0417 15.8333 11.7333 15.8333 10.0833H17.5C17.5 11.7333 16.7666 13.875 15.3 15.325C12.375 18.225 7.62496 18.225 4.69996 15.325C1.7833 12.4333 1.7583 7.73333 4.6833 4.84167C7.6083 1.95 12.3 1.95 15.225 4.84167L17.5 2.5V8.43333ZM10.4166 6.66667V10.2083L13.3333 11.9417L12.7333 12.95L9.16663 10.8333V6.66667H10.4166Z"
                  fill="#9EA0A5"
                />
              </svg>
              {daysAgo(card?.createdAt)}
            </div>
            <div className="active">
              <svg
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="path-1-outside-1_13699_64738"
                  maskUnits="userSpaceOnUse"
                  x="-0.333496"
                  y="1"
                  width="19"
                  height="14"
                  fill="black"
                >
                  <rect
                    fill="white"
                    x="-0.333496"
                    y="1"
                    width="19"
                    height="14"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.99984 3C5.6665 3 2.81984 5.07333 1.6665 8C2.81984 10.9267 5.6665 13 8.99984 13C12.3332 13 15.1798 10.9267 16.3332 8C15.1798 5.07333 12.3332 3 8.99984 3ZM8.99984 11.3333C7.15984 11.3333 5.6665 9.84 5.6665 8C5.6665 6.16 7.15984 4.66667 8.99984 4.66667C10.8398 4.66667 12.3332 6.16 12.3332 8C12.3332 9.84 10.8398 11.3333 8.99984 11.3333ZM8.99984 6C7.89317 6 6.99984 6.89333 6.99984 8C6.99984 9.10667 7.89317 10 8.99984 10C10.1065 10 10.9998 9.10667 10.9998 8C10.9998 6.89333 10.1065 6 8.99984 6Z"
                  />
                </mask>
                <path
                  d="M1.6665 8L0.270957 7.45005L0.0542327 8L0.270957 8.54995L1.6665 8ZM16.3332 8L17.7287 8.54995L17.9454 8L17.7287 7.45005L16.3332 8ZM8.99984 1.5C5.02742 1.5 1.64167 3.97176 0.270957 7.45005L3.06205 8.54995C3.998 6.17491 6.30559 4.5 8.99984 4.5V1.5ZM0.270957 8.54995C1.64167 12.0282 5.02742 14.5 8.99984 14.5V11.5C6.30559 11.5 3.998 9.82509 3.06205 7.45005L0.270957 8.54995ZM8.99984 14.5C12.9723 14.5 16.358 12.0282 17.7287 8.54995L14.9376 7.45005C14.0017 9.82509 11.6941 11.5 8.99984 11.5V14.5ZM17.7287 7.45005C16.358 3.97176 12.9723 1.5 8.99984 1.5V4.5C11.6941 4.5 14.0017 6.17491 14.9376 8.54995L17.7287 7.45005ZM8.99984 9.83333C7.98826 9.83333 7.1665 9.01157 7.1665 8H4.1665C4.1665 10.6684 6.33141 12.8333 8.99984 12.8333V9.83333ZM7.1665 8C7.1665 6.98843 7.98826 6.16667 8.99984 6.16667V3.16667C6.33141 3.16667 4.1665 5.33157 4.1665 8H7.1665ZM8.99984 6.16667C10.0114 6.16667 10.8332 6.98843 10.8332 8H13.8332C13.8332 5.33157 11.6683 3.16667 8.99984 3.16667V6.16667ZM10.8332 8C10.8332 9.01157 10.0114 9.83333 8.99984 9.83333V12.8333C11.6683 12.8333 13.8332 10.6684 13.8332 8H10.8332ZM8.99984 4.5C7.06474 4.5 5.49984 6.06491 5.49984 8H8.49984C8.49984 7.72176 8.7216 7.5 8.99984 7.5V4.5ZM5.49984 8C5.49984 9.93509 7.06474 11.5 8.99984 11.5V8.5C8.7216 8.5 8.49984 8.27824 8.49984 8H5.49984ZM8.99984 11.5C10.9349 11.5 12.4998 9.93509 12.4998 8H9.49984C9.49984 8.27824 9.27808 8.5 8.99984 8.5V11.5ZM12.4998 8C12.4998 6.06491 10.9349 4.5 8.99984 4.5V7.5C9.27808 7.5 9.49984 7.72176 9.49984 8H12.4998Z"
                  fill="#9EA0A5"
                  mask="url(#path-1-outside-1_13699_64738)"
                />
              </svg>
              {card?.property?.total_views?.total}/15 today
            </div>
          </div>
          <div className="boxes">
            <div className="top">
              <div className="box">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.2 6.4H8.8V4.8H7.2V6.4ZM7.2 9.6H8.8V8H7.2V9.6ZM8.8 14.4V11.2H7.2V14.4H4.8V4H7.2H8.8H11.2V14.4H8.8ZM14.4 12.8V14.4H12.8V2.4H11.2H8.8V0H7.2V2.4H4.8H3.2V14.4H1.6V12.8H0V14.4V16H3.2H4.8H11.2H12.8H16V14.4V12.8H14.4Z"
                    fill="white"
                    fill-opacity="0.01"
                  />
                  <mask
                    id="mask0_13699_64758"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="16"
                    height="16"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.2 6.4H8.8V4.8H7.2V6.4ZM7.2 9.6H8.8V8H7.2V9.6ZM8.8 14.4V11.2H7.2V14.4H4.8V4H7.2H8.8H11.2V14.4H8.8ZM14.4 12.8V14.4H12.8V2.4H11.2H8.8V0H7.2V2.4H4.8H3.2V14.4H1.6V12.8H0V14.4V16H3.2H4.8H11.2H12.8H16V14.4V12.8H14.4Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask0_13699_64758)">
                    <rect
                      x="-0.799805"
                      y="-0.800781"
                      width="17.6"
                      height="17.6"
                      fill="#6B6C6F"
                    />
                  </g>
                </svg>
                {card?.property?.property_type}
              </div>
              <div className="box">
                <span>{card?.property?.area_sf}</span>
                Area, SF
              </div>
              <div className="box">
                <span>{card?.property?.Units_Duplex}</span>
                Units Duplex
              </div>
            </div>
            <div className="bottom">
              <div>
                <span>Sold: </span>
                {moment(card?.property?.sold?.date).format("YYYY/MM")} | {formatUSD(card?.property?.sold?.price)}
              </div>
              <div>
                <span>Debt: </span>
                {moment(card?.property?.debt?.date).format("YYYY/MM")} | {formatUSD(card?.property?.debt?.amount)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="property_card_mini-B">
        <PropertyInfoTab
          tabNames={true}
          collapsed={collapsed}
          card={card}
          assetIdList={assetIdList}
        />
      </div>

      <div
        className="view_details_button"
        onClick={() => onClick()}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_13628_124239)">
            <path
              d="M17.2507 7.28585C13.2341 3.2693 6.69932 3.2693 2.68277 7.28585L0 9.96829L2.74932 12.7176C4.75743 14.7257 7.39527 15.7301 10.0334 15.7301C12.6716 15.7301 15.3091 14.7261 17.3176 12.7176L20.0003 10.0348L17.2507 7.28585ZM16.8395 12.2399C13.0865 15.9926 6.98007 15.9926 3.22703 12.2399L0.955405 9.96829L3.16047 7.76322C6.91351 4.01052 13.0199 4.01052 16.773 7.76322L19.0446 10.0348L16.8395 12.2399Z"
              fill="white"
            />
            <path
              d="M9.6527 7.28906C8.34864 7.28906 7.28783 8.34987 7.28783 9.65393C7.28783 9.84041 7.43919 9.99177 7.62567 9.99177C7.81216 9.99177 7.96351 9.84041 7.96351 9.65393C7.96351 8.72251 8.72128 7.96474 9.6527 7.96474C9.83919 7.96474 9.99054 7.81339 9.99054 7.6269C9.99054 7.44041 9.83952 7.28906 9.6527 7.28906Z"
              fill="white"
            />
            <path
              d="M9.99055 5.60156C7.56893 5.60156 5.59866 7.57183 5.59866 9.99345C5.59866 12.4151 7.56893 14.3853 9.99055 14.3853C12.4122 14.3853 14.3824 12.4151 14.3824 9.99345C14.3824 7.57183 12.4125 5.60156 9.99055 5.60156ZM9.99055 13.7097C7.94156 13.7097 6.27433 12.0424 6.27433 9.99345C6.27433 7.94447 7.94156 6.27724 9.99055 6.27724C12.0395 6.27724 13.7068 7.94447 13.7068 9.99345C13.7068 12.0424 12.0399 13.7097 9.99055 13.7097Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_13628_124239">
              <rect
                width="20"
                height="20"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
        <p>View details</p>
      </div>
    </div>
  );
}

function ContactPanel({assetIdList}) {
  // const uniqueAddedBy = [...new Set(assetIdList.map((asset) => asset.addedBy))];
  return (
    <div className="tab_panel_wrapper">
      <div className="contact_container">
        <div className="row">
          <div className="leftSection">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.5625 18H3.43817C2.73178 18 2.21015 17.303 2.47658 16.662C3.71277 13.698 6.61693 12 9.99985 12C13.3838 12 16.288 13.698 17.5241 16.662C17.7906 17.303 17.2689 18 16.5625 18ZM5.91666 5.99998C5.91666 3.79398 7.74899 1.99998 9.99985 1.99998C12.2517 1.99998 14.083 3.79398 14.083 5.99998C14.083 8.20598 12.2517 9.99998 9.99985 9.99998C7.74899 9.99998 5.91666 8.20598 5.91666 5.99998ZM19.9557 17.636C19.2136 14.277 16.8923 11.798 13.837 10.673C15.456 9.39598 16.4002 7.33098 16.0532 5.06998C15.651 2.44698 13.4236 0.347977 10.7348 0.041977C7.02321 -0.381023 3.87507 2.44898 3.87507 5.99998C3.87507 7.88998 4.76929 9.57398 6.1637 10.673C3.10743 11.798 0.787164 14.277 0.044024 17.636C-0.225466 18.857 0.778997 20 2.05397 20H17.9457C19.2217 20 20.2262 18.857 19.9557 17.636Z"
                fill="white"
                fill-opacity="0.01"
              />
              <mask
                id="mask0_13481_26903"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="20"
                height="20"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.5625 18H3.43817C2.73178 18 2.21015 17.303 2.47658 16.662C3.71277 13.698 6.61693 12 9.99985 12C13.3838 12 16.288 13.698 17.5241 16.662C17.7906 17.303 17.2689 18 16.5625 18ZM5.91666 5.99998C5.91666 3.79398 7.74899 1.99998 9.99985 1.99998C12.2517 1.99998 14.083 3.79398 14.083 5.99998C14.083 8.20598 12.2517 9.99998 9.99985 9.99998C7.74899 9.99998 5.91666 8.20598 5.91666 5.99998ZM19.9557 17.636C19.2136 14.277 16.8923 11.798 13.837 10.673C15.456 9.39598 16.4002 7.33098 16.0532 5.06998C15.651 2.44698 13.4236 0.347977 10.7348 0.041977C7.02321 -0.381023 3.87507 2.44898 3.87507 5.99998C3.87507 7.88998 4.76929 9.57398 6.1637 10.673C3.10743 11.798 0.787164 14.277 0.044024 17.636C-0.225466 18.857 0.778997 20 2.05397 20H17.9457C19.2217 20 20.2262 18.857 19.9557 17.636Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_13481_26903)">
                <rect
                  x="-1"
                  y="-1"
                  width="22"
                  height="22"
                  fill="#9EA0A5"
                />
              </g>
            </svg>
            <p>{assetIdList?.addedBy}</p>
          </div>
          {/* <span>{assetIdList?.addedBy}</span> */}
        </div>
        {assetIdList?.date && (
          <div className="row">
            <div className="leftSection">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.9712 9H2.99681V5.971C2.99681 5.435 3.43111 5 3.96625 5H4.99361V7H6.99042V5H12.9776V7H14.9744V5H15.9728C16.524 5 16.9712 5.448 16.9712 6V9ZM16.9712 16C16.9712 16.55 16.522 17 15.9728 17H3.99521C3.44409 17 2.99681 16.552 2.99681 16V11H16.9712V16ZM1.0639 16.761C1.0639 17.865 2.02137 19 3.1236 19H17.098C18.2013 19 19 17.979 19 16.761C19 16.372 18.9681 6.36 18.9681 5.708C18.9681 3.626 18.6875 3 14.9744 3V1H12.9776V3H6.99042V1H4.99361V3H2.99681C1.89856 3 1 3.9 1 5L1.0639 16.761Z"
                  fill="white"
                  fill-opacity="0.01"
                />
                <mask
                  id="mask0_13481_26978"
                  maskUnits="userSpaceOnUse"
                  x="1"
                  y="1"
                  width="18"
                  height="18"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.9712 9H2.99681V5.971C2.99681 5.435 3.43111 5 3.96625 5H4.99361V7H6.99042V5H12.9776V7H14.9744V5H15.9728C16.524 5 16.9712 5.448 16.9712 6V9ZM16.9712 16C16.9712 16.55 16.522 17 15.9728 17H3.99521C3.44409 17 2.99681 16.552 2.99681 16V11H16.9712V16ZM1.0639 16.761C1.0639 17.865 2.02137 19 3.1236 19H17.098C18.2013 19 19 17.979 19 16.761C19 16.372 18.9681 6.36 18.9681 5.708C18.9681 3.626 18.6875 3 14.9744 3V1H12.9776V3H6.99042V1H4.99361V3H2.99681C1.89856 3 1 3.9 1 5L1.0639 16.761Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_13481_26978)">
                  <rect
                    x="-1"
                    y="-1"
                    width="22"
                    height="22"
                    fill="#9EA0A5"
                  />
                </g>
              </svg>
              <p>{moment(assetIdList?.date).format("MMMM Do YYYY, h:mm:ss a")}</p>
            </div>
          </div>
        )}
        {assetIdList?.contactPhone && (
          <div className="row">
            <div className="leftSection">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14 3C14 2.448 13.552 2 13 2H7C6.448 2 6 2.448 6 3V17C6 17.552 6.448 18 7 18H13C13.552 18 14 17.552 14 17V3ZM16 2V18C16 19.105 15.105 20 14 20H6C4.895 20 4 19.105 4 18V2C4 0.895 4.895 0 6 0H14C15.105 0 16 0.895 16 2ZM11.021 15C11.021 15.552 10.573 16 10.021 16C9.469 16 9.021 15.552 9.021 15C9.021 14.448 9.469 14 10.021 14C10.573 14 11.021 14.448 11.021 15Z"
                  fill="white"
                  fill-opacity="0.01"
                />
                <mask
                  id="mask0_13481_26917"
                  maskUnits="userSpaceOnUse"
                  x="4"
                  y="0"
                  width="12"
                  height="20"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14 3C14 2.448 13.552 2 13 2H7C6.448 2 6 2.448 6 3V17C6 17.552 6.448 18 7 18H13C13.552 18 14 17.552 14 17V3ZM16 2V18C16 19.105 15.105 20 14 20H6C4.895 20 4 19.105 4 18V2C4 0.895 4.895 0 6 0H14C15.105 0 16 0.895 16 2ZM11.021 15C11.021 15.552 10.573 16 10.021 16C9.469 16 9.021 15.552 9.021 15C9.021 14.448 9.469 14 10.021 14C10.573 14 11.021 14.448 11.021 15Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_13481_26917)">
                  <rect
                    x="-1"
                    y="-1"
                    width="22"
                    height="22"
                    fill="#9EA0A5"
                  />
                </g>
              </svg>
              {/* <div style={{maxWidth: "200px"}}>
            {card?.contact_info?.phone_numbers?.map((i) => (
              <p>{i}</p>
            ))}
          </div> */}
              <p>{assetIdList?.contactPhone}</p>
            </div>
          </div>
        )}
        {assetIdList?.addedByEmail && (
          <div className="row">
            <div className="leftSection">
              <svg
                width="22"
                height="20"
                viewBox="0 0 22 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="path-1-outside-1_13481_26930"
                  maskUnits="userSpaceOnUse"
                  x="0.666626"
                  y="1.33203"
                  width="21"
                  height="18"
                  fill="black"
                >
                  <rect
                    fill="white"
                    x="0.666626"
                    y="1.33203"
                    width="21"
                    height="18"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.6666 3.33203H4.33329C3.41663 3.33203 2.67496 4.08203 2.67496 4.9987L2.66663 14.9987C2.66663 15.9154 3.41663 16.6654 4.33329 16.6654H17.6666C18.5833 16.6654 19.3333 15.9154 19.3333 14.9987V4.9987C19.3333 4.08203 18.5833 3.33203 17.6666 3.33203ZM17.6666 6.66536L11 10.832L4.33329 6.66536V4.9987L11 9.16536L17.6666 4.9987V6.66536Z"
                  />
                </mask>
                <path
                  d="M2.67496 4.9987L4.67496 5.00036V4.9987H2.67496ZM2.66663 14.9987L0.666626 14.997V14.9987H2.66663ZM11 10.832L9.93996 12.528L11 13.1905L12.06 12.528L11 10.832ZM4.33329 6.66536H2.33329V7.77386L3.27329 8.36136L4.33329 6.66536ZM4.33329 4.9987L5.39329 3.3027L2.33329 1.3902V4.9987H4.33329ZM11 9.16536L9.93996 10.8614L11 11.5239L12.06 10.8614L11 9.16536ZM17.6666 4.9987H19.6666V1.3902L16.6066 3.3027L17.6666 4.9987ZM17.6666 1.33203H4.33329V5.33203H17.6666V1.33203ZM4.33329 1.33203C2.29903 1.33203 0.674959 2.99053 0.674959 4.9987H4.67496C4.67496 5.17353 4.53422 5.33203 4.33329 5.33203V1.33203ZM0.67496 4.99703L0.666627 14.997L4.66663 15.0004L4.67496 5.00036L0.67496 4.99703ZM0.666626 14.9987C0.666626 17.0199 2.31206 18.6654 4.33329 18.6654V14.6654C4.5212 14.6654 4.66663 14.8108 4.66663 14.9987H0.666626ZM4.33329 18.6654H17.6666V14.6654H4.33329V18.6654ZM17.6666 18.6654C19.6879 18.6654 21.3333 17.0199 21.3333 14.9987H17.3333C17.3333 14.8108 17.4787 14.6654 17.6666 14.6654V18.6654ZM21.3333 14.9987V4.9987H17.3333V14.9987H21.3333ZM21.3333 4.9987C21.3333 2.97746 19.6879 1.33203 17.6666 1.33203V5.33203C17.4787 5.33203 17.3333 5.1866 17.3333 4.9987H21.3333ZM16.6066 4.96937L9.93996 9.13604L12.06 12.528L18.7266 8.36136L16.6066 4.96937ZM12.06 9.13604L5.39329 4.96937L3.27329 8.36136L9.93996 12.528L12.06 9.13604ZM6.33329 6.66536V4.9987H2.33329V6.66536H6.33329ZM3.27329 6.69469L9.93996 10.8614L12.06 7.46937L5.39329 3.3027L3.27329 6.69469ZM12.06 10.8614L18.7266 6.69469L16.6066 3.3027L9.93996 7.46937L12.06 10.8614ZM15.6666 4.9987V6.66536H19.6666V4.9987H15.6666Z"
                  fill="#9EA0A5"
                  mask="url(#path-1-outside-1_13481_26930)"
                />
              </svg>
              <p>{assetIdList?.addedByEmail}</p>
            </div>
          </div>
        )}
        {assetIdList?.contactWebsite && (
          <div className="row">
            <div className="leftSection">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18.2387 1.76128C15.8904 -0.587093 12.0827 -0.587093 9.7344 1.76128L7.16555 4.33016L8.58278 5.7474L11.1516 3.17852C12.7182 1.61194 15.254 1.61194 16.8215 3.17852C18.3841 4.7421 18.3841 7.28491 16.8215 8.84849L14.2527 11.4174L15.6699 12.8346L18.2387 10.2657C20.5871 7.91736 20.5871 4.10965 18.2387 1.76128ZM8.84838 16.8207C8.09166 17.5785 7.08437 17.9954 6.01393 17.9954C4.9435 17.9954 3.9362 17.5785 3.17948 16.8207C1.61593 15.2582 1.61593 12.7143 3.17948 11.1518L5.74833 8.58288L4.3301 7.16464L1.76126 9.73352C-0.587086 12.0819 -0.587086 15.8896 1.76126 18.239C2.93593 19.4127 4.47443 20 6.01393 20C7.55343 20 9.09193 19.4127 10.2666 18.239L12.8355 15.6701L11.4172 14.2519L8.84838 16.8207ZM12.2631 6.31971L13.6804 7.73695L7.72182 13.6956L6.30459 12.2783L12.2631 6.31971Z"
                  fill="white"
                  fill-opacity="0.01"
                />
                <mask
                  id="mask0_13481_26959"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.2387 1.76128C15.8904 -0.587093 12.0827 -0.587093 9.7344 1.76128L7.16555 4.33016L8.58278 5.7474L11.1516 3.17852C12.7182 1.61194 15.254 1.61194 16.8215 3.17852C18.3841 4.7421 18.3841 7.28491 16.8215 8.84849L14.2527 11.4174L15.6699 12.8346L18.2387 10.2657C20.5871 7.91736 20.5871 4.10965 18.2387 1.76128ZM8.84838 16.8207C8.09166 17.5785 7.08437 17.9954 6.01393 17.9954C4.9435 17.9954 3.9362 17.5785 3.17948 16.8207C1.61593 15.2582 1.61593 12.7143 3.17948 11.1518L5.74833 8.58288L4.3301 7.16464L1.76126 9.73352C-0.587086 12.0819 -0.587086 15.8896 1.76126 18.239C2.93593 19.4127 4.47443 20 6.01393 20C7.55343 20 9.09193 19.4127 10.2666 18.239L12.8355 15.6701L11.4172 14.2519L8.84838 16.8207ZM12.2631 6.31971L13.6804 7.73695L7.72182 13.6956L6.30459 12.2783L12.2631 6.31971Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_13481_26959)">
                  <rect
                    x="-1"
                    y="-1"
                    width="22"
                    height="22"
                    fill="#9EA0A5"
                  />
                </g>
              </svg>
              <p>{assetIdList?.contactWebsite}</p>
            </div>
            <a
              href="https://www.google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.8333 15.8333H4.16667V4.16667H10V2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.24167 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V10H15.8333V15.8333ZM11.6667 2.5V4.16667H14.6583L6.46667 12.3583L7.64166 13.5333L15.8333 5.34167V8.33333H17.5V2.5H11.6667Z"
                  fill="#9EA0A5"
                />
              </svg>
            </a>
          </div>
        )}
        {assetIdList?.contactPhone && (
          <div className="row">
            <div className="leftSection">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16 15C16 15.552 15.552 16 15 16H5C4.448 16 4 15.552 4 15C4 14.448 4.448 14 5 14H15C15.552 14 16 14.448 16 15ZM5 10H15C15.552 10 16 10.448 16 11C16 11.552 15.552 12 15 12H5C4.448 12 4 11.552 4 11C4 10.448 4.448 10 5 10ZM18 17C18 17.552 17.552 18 17 18H3C2.448 18 2 17.552 2 17V3C2 2.448 2.448 2 3 2H12V6C12 7.104 12.895 8 14 8H18V17ZM19.707 5.707L14.293 0.293C14.105 0.105 13.851 0 13.586 0H2C0.895 0 0 0.895 0 2V18C0 19.104 0.895 20 2 20H18C19.105 20 20 19.104 20 18V6.414C20 6.149 19.895 5.895 19.707 5.707Z"
                  fill="white"
                  fill-opacity="0.01"
                />
                <mask
                  id="mask0_13481_26938"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16 15C16 15.552 15.552 16 15 16H5C4.448 16 4 15.552 4 15C4 14.448 4.448 14 5 14H15C15.552 14 16 14.448 16 15ZM5 10H15C15.552 10 16 10.448 16 11C16 11.552 15.552 12 15 12H5C4.448 12 4 11.552 4 11C4 10.448 4.448 10 5 10ZM18 17C18 17.552 17.552 18 17 18H3C2.448 18 2 17.552 2 17V3C2 2.448 2.448 2 3 2H12V6C12 7.104 12.895 8 14 8H18V17ZM19.707 5.707L14.293 0.293C14.105 0.105 13.851 0 13.586 0H2C0.895 0 0 0.895 0 2V18C0 19.104 0.895 20 2 20H18C19.105 20 20 19.104 20 18V6.414C20 6.149 19.895 5.895 19.707 5.707Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_13481_26938)">
                  <rect
                    x="-1"
                    y="-1"
                    width="22"
                    height="22"
                    fill="#9EA0A5"
                  />
                </g>
              </svg>
              {/* <p>{card?.contact_info?.brochure}</p> */}files
            </div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.8333 15.8333H4.16667V4.16667H10V2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.24167 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V10H15.8333V15.8333ZM11.6667 2.5V4.16667H14.6583L6.46667 12.3583L7.64166 13.5333L15.8333 5.34167V8.33333H17.5V2.5H11.6667Z"
                fill="#9EA0A5"
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
}
