import React from "react";
import {useSelector} from "react-redux";
import "./Deals.scss";
import dot from "../../assets/svg/dot.svg";
import blubar from "../../assets/svg/bluebar.svg";
import greenbar from "../../assets/svg/greenbar.svg";
import orangebar from "../../assets/svg/orangebar.svg";
import redbar from "../../assets/svg/redbar.svg";
import info from "../../assets/svg/info.svg";
import status from "../../assets/svg/status.svg";
import success from "../../assets/svg/sucess.svg";
import dealcard from "../../assets/Image/deals/dealcard.png";
import {Link, useNavigate} from "react-router-dom";
import {setAssetId, setAssetName, setChatId, setUserAssetData} from "../../store/reducers/userDetailsSlice";
import {useDispatch,} from "react-redux";
import {selectAsset} from "../../store/reducers/DealsSyndicate";
import {format} from "date-fns";
import {selectDeal} from "../../store/reducers/deals";
import {clearDealsroomData, setShowDealsroomData} from "../../store/reducers/DealsRoom/dealsroomSlice.js";
import profileimages from '../../assets/profile.png'

const DealCard = ({data, setSelectedAssetData, roleType, onSelect}) => {
  let profileimage = useSelector((state) => state.auth?.userInformation?.profile_image);
  console.log("profileimage",profileimage)

  let dispatch = useDispatch();
  const navigate = useNavigate()
  const handleSelectAsset = () => {
    dispatch(selectAsset(data));
    if (onSelect) {
      onSelect(data);
    }
  };

  const formattedDate = data?.date ? format(new Date(data.date), "MMMM dd, yyyy") : "";

  const dealsroom = () => {
    dispatch(setShowDealsroomData(true));
    navigate(`/${data?._id}/dealsroom`);
  };

  return (
    <div
      className="deal-card"
      onClick={handleSelectAsset}
    >
      <div
        onClick={() => {
          dispatch(setUserAssetData(data));
        }}
        className="deal-card-upper"
      >
        <img
          src={data?.assetsImages?.[0] ? data.assetsImages[0] : dealcard}
          alt="Asset"
        />
        <div className="deal-c-u-t">
          <span>{data?.assetName}</span>
          <span>{data?.assetsAddress}</span>
        </div>
        <div className="dot">
          <img
            src={dot}
            alt="image"
          />
        </div>
      </div>
      <div onClick={dealsroom}>
        <div className="deal-card-center">
          <div className="deal-card-c-inner">
            <div className="deal-card-list">
              <span>Property type</span>
              <span>{data?.assetType}</span>
            </div>
            <div className="deal-card-list">
              <span>Deal type</span>
              <span>Acquisition</span>
            </div>
            <div className="deal-card-list">
              <span>Market</span>
              <span>Baltimore</span>
            </div>
          </div>
          <div className="deal-card-c-inner">
            <div className="deal-card-list">
              <span>Gross SF</span>
              <span>100,000</span>
            </div>
            <div className="deal-card-list">
              <span>Price</span>
              <span>{data?.assetValue?.toLocaleString()}</span>
            </div>
            <div className="deal-card-list">
              <span>Price GSF</span>
              <span>{data?.pricePerUnit}</span>
            </div>
          </div>

          <div className="divider"></div>

          <div className="deal-card-progress">
            <div className="card-progress">
              <div>
                <span>Progress</span>
                <img
                  src={info}
                  alt="image"
                />
              </div>
              <div>
                <img
                  src={status}
                  alt="image"
                />
                <span>{data?.status}</span>
              </div>
            </div>
            <div className="bar-container">
              <div className="bar-wrapper">
                <span>21/30</span>
                <img
                  src={blubar}
                  alt="image"
                />
              </div>
              <div className="bar-wrapper">
                <span>21/30</span>
                <img
                  src={greenbar}
                  alt="image"
                />
              </div>
              <div className="bar-wrapper">
                <span>21/30</span>
                <img
                  src={orangebar}
                  alt="image"
                />
              </div>
              <div className="bar-wrapper">
                <span>21/30</span>
                <img
                  src={redbar}
                  alt="image"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="deal-card-bottom">
          <div>
            <div>
              <img
                src={profileimage || profileimages}
             
                alt="image"
              />
            </div>
            <div>
              <div className="dealcard-success">
                <img
                  src={success}
                  alt="image"
                />
                <span>Owned by</span>
              </div>
              <div className="dealcard-s-name">
                <span>{data?.addedBy}</span>
              </div>
            </div>
          </div>
          <div className="dealcard-date">
            <span>{formattedDate} 2:23 pm</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealCard;
