import "./index.scss";

function LoaderOverlay() {
  return (
    <div className="loader_overlay">
      <div className="container">
        <div className="flower-logo">
          <div className="petal petal1"></div>
          <div className="petal petal2"></div>
          <div className="petal petal3"></div>
          <div className="petal petal4"></div>
          <div className="petal petal5"></div>
        </div>
        <div className="shadow"></div>
      </div>
    </div>
  );
}

export default LoaderOverlay;
