const Endpoint = {
  // User
  getUserDetails: "api/auth/user-details",
  Signupdata: "api/auth/register",
  updatepassword: "api/auth/update-password",
  getUserPortfolio: "users/asset-discovery",
  UserLogIn: "api/auth/login",
  PostQuestionsData: "api/auth/persona/add",
  VerifyEmail: "/api/auth/sendVerificationEmail",

  // Assets
  // getAsset:`api/assets/search/assets/?assets_name=${query}`,
  PostLoanSetupData: "api/loan/add",
  FetchValuationInfo: "/api/deal-economics/valuation",
  fetchCrownFunding: "/api/crowdfunding",

  // SETUP_SYNDICATE
  PostSetupSyndicate: "api/add-Syndicate",
  GetSyndicates: "api/Syndicates",

  // Manage Syndicate
  PostManageSindicate: "/api/Add/manageStakeholders",

  // portfolio
  AssetHeader: "api/Portfolio/getAccessGivenByEmail",
  Maintenance: "api/Portfolio/getallownerdata",
  getAdmindata: `api/Portfolio/get-portfolio-by-ID`,
  addingfetchinvistor: "api/Portfolio/giveAsccess",
  Sendemailtgiveaccess: "api/Portfolio/giveAccess",
  linechartdata: "/api/Portfolio/getallownerdata",
  getassetbyid: "api/Portfolio/getallownerdata",
  getallownerdata: "api/Portfolio/getallownerdata",
  getassectbyemail: "api/Portfolio/getAccessGivenByAdmin",
  fetchMurndataByEmail: (email) => `api/murndata/get-murndata-by-email?email=${email}`,
  UpdateAsset: (id) => `/api/portfolio/updateAsset/${id}`,
  // Deals
  startDeals: (id) => `api/Deals/StartDeals/${id}`,
  addCheckList: (id) => `/api/assets/:assetId`,
  Similar_assets: (assetId) => `api/assets/GetSimilarProperties/${assetId}`,
  
  // portfolio - 
  // Maintenance
  getallmaintance: '/api/Portfolio/Maintenance'
};

export default Endpoint;
