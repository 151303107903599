import Signup from "../pages/signup/Signup";
import Signin from "../pages/signin/Signin";
import "react-phone-number-input/style.css";
import SuccessfulSignup from "../pages/signup/SuccessfulSignup";
import PageLayout from "../components/pageLayout/PageLayout";
import PageLayoutWithHeader from "../components/pageLayout/payLayoutWithHeader";
import SignupPricing from "../pages/SignupPricing/SignupPricing";
import AssetDiscovery from "../pages/assetDiscovery/AssetDiscovery";
import CrowdFunding from "../pages/crowdFunding/CrowdFunding";
import PasswordRecovery from "../pages/passwordRecovery/PasswordRecovery";
import Deals from "../pages/deals/Deals";
import DealsRoom from "../pages/DealsRoom/DealsRoom";
import Portfolio from "../pages/portfolio/Portfolio";
import EditAssetInfo from "../pages/portfolio/EditAssetInfo";
import AssetDashboard from "../pages/portfolio/AssetDashboard";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import PrivateRoutes from "./PrivateRoutes";
import SignupLender from "../pages/signup/SignupLender";
import SignupBroker from "../pages/signup/SignupBroker";
import SignupQuestionLender from "../pages/SignupQuestions/SignupQuestionLender";
import SuccessfulSignupLender from "../pages/signup/SuccessfulSignupLender";
import SignupLanding from "../pages/signup/SignupLanding";
import AssetSearch from "../pages/assetSearch/AssetSearch";
import AddDoc, {ManualdocCreation} from "../pages/addDoc/AddDoc";
import Overview from "../pages/overview/overview";
import NewLoanReq from "../pages/newLoanReq/NewLoanReq";
import NewSyndicate from "../pages/StartSyndicate/NewSyndicate";
import AiSearchComp from "../pages/AiSearch/AiSearchComp";
import "../App.scss";
import SignupQuestionBroker from "../pages/SignupQuestions/SignupQuestionBroker";
import SuccessfulSignupBroker from "../pages/signup/SuccessfulSignupBroker";
import Compare from "../pages/compare/Compare";
import Profile from "../pages/profile/Profile";
import CompareAssets from "../pages/compare/CompareAssets";
import Task from "../pages/Tasks/Task";
import Disposition from "../pages/disposition/Disposition";
import Opportunities from "../pages/Opportunities/Opportunities";
import OpportunitiesSearch from "../pages/Opportunities/OpportunitiesSearch/OpportunitiesSearch";
import DiscoveredOpp from "../pages/Opportunities/DiscoveredOpp";
import LilypadTreasury from "../pages/LilypadTreasury/LilypadTreasury";
import MarketFund from "../pages/LilypadTreasury/components/MarketFund";
import React, {useState, useEffect} from "react";
import {SignupQuestionsNew} from "../pages/SignupQuestions/SignupQuestionsNew";
import PageNotFound from "../components/ErrorScreen/PageNotFound";
import InvestmentPipeline from "../pages/InvestmentPipeline/InvestmentPipeline";
import InvestmentSubPages from "../pages/InvestmentPipeline/Content/Investment/SubPages/InvestmentSubPage";
import NewOfferingsSubPages from "../pages/NewOfferingsSubPages/NewOfferingsSubPages";
import SetupDoc from "../pages/DealsRoom/SetupDoc";
import AssetCardForPortfolio from "../pages/DealsRoom/AssetCardForPortfolio";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <PageLayout crossActive={false}>
              <Overview />
            </PageLayout>
          }
        />
        <Route
          path="/signup"
          element={
            <PageLayout crossActive={false}>
              <SignupLanding />
            </PageLayout>
          }
        />
        <Route
          path="/signup/investor"
          element={
            <PageLayout crossActive={false}>
              <Signup />
            </PageLayout>
          }
        />
        <Route
          path="/signup/lender"
          element={
            <PageLayout crossActive={false}>
              <SignupLender />
            </PageLayout>
          }
        />
        <Route
          path="/signup/broker"
          element={
            <PageLayout crossActive={false}>
              <SignupBroker />
            </PageLayout>
          }
        />
        <Route
          path="/signin"
          element={
            <PageLayout crossActive={false}>
              <Signin />
            </PageLayout>
          }
        />
        <Route
          path="/password-recovery"
          element={
            <PageLayout crossActive={false}>
              <PasswordRecovery />
            </PageLayout>
          }
        />
        <Route element={<PrivateRoutes />}>
          <Route element={<PrivateRoutes />}>
            <Route
              path="/asset-discovery"
              element={
                <PageLayoutWithHeader crossActive={false}>
                  <AssetDiscovery />
                </PageLayoutWithHeader>
              }
            />
          </Route>

          <Route
            path="/crowdfunding"
            element={
              <PageLayoutWithHeader crossActive={false}>
                <CrowdFunding />
              </PageLayoutWithHeader>
            }
          />
          <Route
            path="/compare"
            element={
              <PageLayoutWithHeader crossActive={false}>
                <Compare />
              </PageLayoutWithHeader>
            }
          />
          <Route
            path="/opportunities"
            element={
              <PageLayoutWithHeader crossActive={false}>
                <Opportunities />
              </PageLayoutWithHeader>
            }
          />
          <Route
            path="/opportunities/discovered_opportunities"
            element={
              <PageLayoutWithHeader crossActive={false}>
                <DiscoveredOpp />
              </PageLayoutWithHeader>
            }
          />
          <Route
            path="/market_fund"
            element={
              <PageLayoutWithHeader crossActive={false}>
                <MarketFund />
              </PageLayoutWithHeader>
            }
          />
          <Route
            path="/opportunities-search"
            element={
              <PageLayoutWithHeader crossActive={false}>
                <OpportunitiesSearch />
              </PageLayoutWithHeader>
            }
          />
          <Route
            path="/compareassets"
            element={
              <PageLayoutWithHeader crossActive={false}>
                <CompareAssets />
              </PageLayoutWithHeader>
            }
          />
          <Route
            path="/tasks"
            element={
              <PageLayoutWithHeader crossActive={false}>
                <Task />
              </PageLayoutWithHeader>
            }
          />
          <Route
            path="/profile"
            element={
              <PageLayoutWithHeader crossActive={false}>
                <Profile />
              </PageLayoutWithHeader>
            }
          />
          <Route
            path="/asset-search"
            element={
              <PageLayoutWithHeader crossActive={false}>
                <AssetSearch />
              </PageLayoutWithHeader>
            }
          />
          <Route
            path="/ai-search"
            element={
              <PageLayoutWithHeader crossActive={false}>
                <AiSearchComp />
              </PageLayoutWithHeader>
            }
          />
          <Route
            path="/deals"
            element={
              <PageLayoutWithHeader crossActive={false}>
                <Deals />
              </PageLayoutWithHeader>
            }
          />
          <Route
            path="/dealsroom"
            element={
              <PageLayoutWithHeader crossActive={false}>
                <DealsRoom />
              </PageLayoutWithHeader>
            }
          />
          <Route
            path="/:id/dealsroom"
            element={
              <PageLayoutWithHeader crossActive={false}>
                <DealsRoom />
              </PageLayoutWithHeader>
            }
          />
          <Route
            path="/:id/portfolio/dealsroom"
            element={
              <PageLayoutWithHeader crossActive={false}>
                <AssetCardForPortfolio />
              </PageLayoutWithHeader>
            }
          />
          <Route
            path="/manage-syndicate/:assetId/:syndicateId"
            element={
              <PageLayoutWithHeader crossActive={false}>
                <LilypadTreasury />
              </PageLayoutWithHeader>
            }
          />
          <Route
            path="/dealsroom/adddoc"
            element={
              <PageLayoutWithHeader crossActive={false}>
                <AddDoc />
              </PageLayoutWithHeader>
            }
          />
          <Route
            path="/dealsroom/adddoc/manualdoc"
            element={
              <PageLayoutWithHeader crossActive={false}>
                <ManualdocCreation />
              </PageLayoutWithHeader>
            }
          />
          <Route
            path="/newloanreq"
            element={
              <PageLayoutWithHeader crossActive={false}>
                <NewLoanReq />
              </PageLayoutWithHeader>
            }
          />
          <Route
            path="/newsyndicate"
            element={
              <PageLayoutWithHeader crossActive={false}>
                <NewSyndicate />
              </PageLayoutWithHeader>
            }
          />
          <Route
            path="/signup/investor/pricing"
            element={
              <PageLayout crossActive={true}>
                <SignupPricing />
              </PageLayout>
            }
          />
          <Route
            path="/signup/investor/questions"
            element={
              <PageLayout crossActive={true}>
                <SignupQuestionsNew />
              </PageLayout>
            }
          />
          <Route
            path="/signup/lender/questions"
            element={
              <PageLayout crossActive={true}>
                <SignupQuestionLender />
              </PageLayout>
            }
          />
          <Route
            path="/signup/broker/questions"
            element={
              <PageLayout crossActive={true}>
                <SignupQuestionBroker />
              </PageLayout>
            }
          />
          <Route
            path="/signup/investor/successfull"
            element={
              <PageLayout crossActive={false}>
                <SuccessfulSignup />
              </PageLayout>
            }
          />
          <Route
            path="/signup/lender/successfull"
            element={
              <PageLayout crossActive={false}>
                <SuccessfulSignupLender />
              </PageLayout>
            }
          />
          <Route
            path="/signup/broker/successfull"
            element={
              <PageLayout crossActive={false}>
                <SuccessfulSignupBroker />
              </PageLayout>
            }
          />
          <Route
            path="/:id/portfolio"
            element={
              <PageLayoutWithHeader crossActive={false}>
                <Portfolio />
              </PageLayoutWithHeader>
            }
          />
          <Route
            path="/portfolio/edit-asset-info"
            element={
              <PageLayoutWithHeader crossActive={false}>
                <EditAssetInfo />
              </PageLayoutWithHeader>
            }
          />
          <Route
            path="/portfolio/:id/start-disposition"
            element={
              <PageLayoutWithHeader crossActive={false}>
                <Disposition />
              </PageLayoutWithHeader>
            }
          />
          <Route
            path="/portfolio/:name/asset-dashboard"
            element={
              <PageLayoutWithHeader crossActive={false}>
                <AssetDashboard />
              </PageLayoutWithHeader>
            }
          />

          <Route
            path="/error"
            element={
              <PageLayoutWithHeader crossActive={false}>
                <PageNotFound />
              </PageLayoutWithHeader>
            }
          />
          <Route
            path="/investment-pipeline"
            element={
              <PageLayoutWithHeader crossActive={false}>
                <InvestmentPipeline />
              </PageLayoutWithHeader>
            }
          />
          <Route
            path="/investment-pipeline/new-offerings"
            element={
              <PageLayoutWithHeader crossActive={false}>
                <NewOfferingsSubPages />
              </PageLayoutWithHeader>
            }
          />
          <Route
            path="/investment/apartment-details/*"
            element={
              <PageLayoutWithHeader crossActive={false}>
                <InvestmentSubPages />
              </PageLayoutWithHeader>
            }
          />
        </Route>
        <Route
          path="/:id/setup-doc"
          element={
            <PageLayoutWithHeader crossActive={false}>
              <SetupDoc />
            </PageLayoutWithHeader>
          }
        />
        <Route
          path="*"
          element={
            <PageLayoutWithHeader crossActive={false}>
              <PageNotFound />
            </PageLayoutWithHeader>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
