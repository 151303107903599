import React from "react";
import { useNavigate } from "react-router-dom";
import "./PageNotFound.scss";

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center min-h-screen">
      <section className="page_404 bg-white text-center p-6">
        <div className="container-data">
          <div className="four_zero_four_bg">
            <h1 className="text-9xl font-bold">404</h1>
          </div>
          <hr className="my-6 border-gray-300" />
          <div className="contant_box_404">
            <button
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
              onClick={() => navigate(-1)}
            >
              Go Back
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PageNotFound;
